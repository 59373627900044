import { create } from "zustand";
import { persist } from "zustand/middleware";

export type CartItem = {
  sku: number;
  quantity: number;
  currency: string;
  price: number | string;
  surcharge?: number | string;
  markup: number;
  image: string;
  title: string;
  delivery_days: number;
  next_delivery_date: string;
  variants?: any;
  stock_availability?: boolean;
  old_price?: string | null;
};

type Fee = {
  type: "amount" | "percentage";
  value: number;
};
type Method = {
  id: number;
  title: string;
  display: string;
  description: string;
  enabled: boolean;
  label?: string;
  labelColor?: string;
  fee?: Fee;
  dueToday: boolean;
};


interface ShoppingCartProps {
  
  cartItems: CartItem[];
  addToCart: (cartItem: CartItem) => void;
  removeOneFromCart: (
    sku: number,
    variants?: { variant: string; value: string }[]
  ) => void;
  removeFromCart: (
    sku: number,
    variants?: { variant: string; value: string }[]
  ) => void;
  totalCartItems: () => number;
  totalCartPrice: (staticCart?: CartItem[]) => number;
  surcharge: number;
  setSurcharge: (surcharge: number) => void;
  selectedPaymentMethod: Method;
  setSelectedPaymentMethod: (method: Method) => void;
  totalCartOldPrice: (staticCart?: CartItem[]) => number;
  productCartQuantity: (
    sku: number,
    variants?: { variant: string; value: string }[]
  ) => number;
  emptyCart: () => void;
  replaceCart: (cartItems: CartItem[]) => void;
  getLargeApplianceSurcharge: () => number;
}

const useShoppingCart = create<ShoppingCartProps>()(
  persist(
    (set, get) => ({
      scheduleData: null,
      cartItems: [],
      surcharge: 0,
      setSurcharge: (val: number) =>
        set(() => ({
          surcharge: val,
        })),
      selectedPaymentMethod: null,
      setSelectedPaymentMethod: (val: Method) =>
        set(() => ({
          //TODO: pass in the values  of a payment option selected by the user
          selectedPaymentMethod: val,
          
        })),
      addToCart: (cartItem: CartItem) => {
        set((state) => {
          const existingItemIndex = state.cartItems.findIndex(
            (item) =>
              item.sku === cartItem.sku &&
              JSON.stringify(item.variants || []) ===
                JSON.stringify(cartItem.variants || [])
          );

          if (existingItemIndex !== -1) {
            // Product already exists with the same variants, update quantity
            const updatedCartItems = [...state.cartItems];
            updatedCartItems[existingItemIndex].quantity += cartItem.quantity;
            return { cartItems: updatedCartItems };
          }

          // Product doesn't exist or has different variants, add new entry
          return {
            cartItems: [...state.cartItems, cartItem],
          };
        });
      },
      removeOneFromCart: (
        sku: number,
        variants?: { variant: string; value: string }[]
      ) => {
        set((state) => {
          const existingItemIndex = state.cartItems.findIndex(
            (item) =>
              item.sku === sku &&
              JSON.stringify(item.variants || []) ===
                JSON.stringify(variants || [])
          );

          if (existingItemIndex !== -1) {
            const updatedCartItems = [...state.cartItems];
            const existingItem = updatedCartItems[existingItemIndex];

            if (existingItem.quantity > 1) {
              existingItem.quantity -= 1;
            } else {
              updatedCartItems.splice(existingItemIndex, 1);
            }

            return { cartItems: updatedCartItems };
          }
          return {};
        });
      },
      removeFromCart: (
        sku: number,
        variants?: { variant: string; value: string }[]
      ) => {
        set((state) => ({
          cartItems: state.cartItems.filter(
            (item) =>
              item.sku !== sku ||
              JSON.stringify(item.variants || []) !==
                JSON.stringify(variants || [])
          ),
        }));
      },

      totalCartItems: () => {
        const state = get();
        return state.cartItems.reduce(
          (total, item) => total + item.quantity,
          0
        );
      },
      totalCartPrice: (staticCart?: CartItem[]): number => {
        const state = get();

        return staticCart
          ? staticCart.reduce(
              (total: number, item: any) => total + item.price * item.quantity,
              0
            )
          : state.cartItems.reduce(
              (total: number, item: any) => total + item.price * item.quantity,
              0
            );
      },
      totalCartOldPrice: (staticCart?: CartItem[]): number => {
        const state = get();

				if (staticCart) {
					return staticCart.reduce(
						(total: number, item: any) =>
							total +
							(item?.old_price ?? item.price) * item.quantity,
						0
					);
				} else {
					return state.cartItems.reduce(
						(total: number, item: any) =>
							total +
							(item?.old_price ?? item.price) * item.quantity,
						0
					);
				}
			},
			getLargeApplianceSurcharge: () => {
				const { cartItems } = get();
				return Math.round(
					cartItems.reduce(
						(total, item: any) =>
							total + ((item.surcharge > 0 || (item.original_zar_price >= 5000 )) ? item.price * item.quantity * 0.05 : 0),
						0
					)
				);

			},
			productCartQuantity: (
				sku: number,
				variants?: { variant: string; value: string }[]
			) => {
				const state = get();
				// Filter items with the same SKU
				const filteredItems = state.cartItems.filter(
					(item) =>
						item.sku === sku &&
						JSON.stringify(item.variants || []) ===
							JSON.stringify(variants || [])
				);
				// Sum up the quantities of filtered items
				return filteredItems.reduce(
					(total, item) => total + item.quantity,
					0
				);
			},
			emptyCart: () => set({ cartItems: [] }),
			replaceCart: (cartItems: CartItem[]) => {
				set((state) => {
					return {
						cartItems,
					};
				});
			},
		}),
		{
			name: "dailysale-shop-cart",
		}
	)
);

export default useShoppingCart;
